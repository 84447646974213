/* Imports fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap');
@font-face {
    font-family: neon;
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
}

:root {
    --text: hsl(206.25, 100%, 6.27%);
    --bg: hsl(202.5, 14.81%, 89.41%);
    --btn: hsl(203.46, 55.19%, 52.75%);
    /* --inverse-bg: var(--text);
    --inverse-text: var(--bg); */
}

/* @media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(206.25, 100%, 6.27%);
        --text: hsl(202.5, 14.81%, 89.41%);
        --inverse-bg: var(--text);
        --inverse-text: var(--bg);
    }
} */

/* Basic page styling */
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: var(--bg);
}
